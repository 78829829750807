<template>
  <div class="wrapper" id="oemSet" ref="page">
    <ctheader></ctheader>
    <div class="content">
      <div class="table-head">
        <button class="btn primary ver-ali-top" @click="newVersion">
          新建
        </button>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th class="pw50">版本名称</th>
              <th class="pw50">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataList">
              <!-- <td class="pw10">{{row.id}}</td> -->
              <td class="pw50">{{ row.name }}</td>
              <td class="operation pw50">
                <a @click="edit(row)">编辑</a>
                <a @click="operate(row.id)">删除</a>
              </td>
            </tr>
            <tr v-if="dataList.length === 0">
              <td class="empty" colspan="2">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination
      :start="search.start"
      :total="search.total"
      :page-length="search.length"
      :pageCallback="getList"
    >
    </pagination>
    <layers
      class="barcode-layer"
      :show="layerShow"
      :title="layerTitle"
      :width="500"
      :showFoot="true"
      :btnLoadDisabled="layerDisabled"
      :saveCallBack="layerSave"
      :closeCallBack="layerCancel"
    >
      <div class="form">
        <div class="field">
          <label>版本名称</label>
          <input class="w300" type="text" v-model="side.name" />
        </div>
        <div class="field">
          <label>系统名称</label>
          <input class="w300" type="text" v-model="side.sys_name" />
        </div>
        <div class="field">
          <label>企业logo</label>
          <el-upload
            action="/api/menu"
            :data="{
              action: 'company.logo.upload',
            }"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            list-type="picture-card"
            :class="{ disUoloadSty: fileList.length > 0 }"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="field">
          <label>启动页广告</label>
          <el-upload
            action="/api/menu"
            :data="{
              action: 'company.logo.upload',
            }"
            :on-success="handleSuccess1"
            :on-remove="handleRemove1"
            :file-list="fileList1"
            list-type="picture-card"
            :class="{ disUoloadSty: fileList1.length > 0 }"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="field">
          <label>客显默认广告</label>
          <el-upload
            action="/api/menu"
            :data="{
              action: 'company.logo.upload',
            }"
            :on-success="handleSuccess2"
            :on-remove="handleRemove2"
            :file-list="fileList2"
            list-type="picture-card"
            :class="{ disUoloadSty: fileList2.length > 0 }"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
    </layers>
    <mimesis
      :show="ensureShow"
      :closeCallBack="ensureCancel"
      :saveCallBack="ensureDone"
      :btnLoadDisabled="endisabled"
      :showIcon="true"
      :isDangerous="true"
      saveBtn="确认"
    >
      <div>确认删除吗？</div>
    </mimesis>
  </div>
</template>

<script>
/**
 * @file pages/oemSet.vue
 * @desc this is oemSet.vue
 * @version 0.1.0
 * @author qijiajun <songkexin@rongyi.com>
 * @since 2021-07-29
 * @copyright 2021
 */
/* jshint esversion: 6 */
import { mapGetters, mapActions } from "vuex";
// import router from '@/router';
// import * as URL from '_/URLs';
import APIs from "../../modules/APIs";
import { apiPost } from "../../modules/utils";
// import ctheader from '@@/ctheader';
// import pagination from '@@/pagination';
// import layers from '@@/layers';
// import mimesis from '@@/mimesis';
import layers from "../../components/layers";
import mimesis from "../../components/mimesis";
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

/**
 * @module oenSet.vue
 * @desc this is module oenSet.vue
 * @author qijiajun <songkexin@rongyi.com>
 * @since 2021-07-29
 * @copyright 2021
 */
export default {
  data() {
    return {
      dataList: [],
      search: {
        start: 0,
        length: 10,
        total: 0,
      },
      layerTitle: "新增",
      layerDisabled: false,
      layerShow: false,
      endisabled: false,
      ensureShow: false,
      id: "",
      name: "",
      isEdit: false,
      fileList: [],
      fileList1: [],
      fileList2: [],

      side: {},
    };
  },
  components: { ctheader, pagination, layers, mimesis },
  computed: {
    ...getters,
  },
  methods: {
    handleSuccess(res) {
      if (res.code == 0) {
        this.side.sys_logo = "http://" + res.data.domain + "/" + res.data.path;
        this.fileList = [{ url: this.side.sys_logo }];
      }
    },
    handleSuccess1(res) {
      if (res.code == 0) {
        this.side.start_image =
          "http://" + res.data.domain + "/" + res.data.path;
        this.fileList1 = [
          {
            url: this.side.start_image,
          },
        ];
      }
    },
    handleSuccess2(res) {
      if (res.code == 0) {
        this.side.default_image =
          "http://" + res.data.domain + "/" + res.data.path;
        this.fileList2 = [
          {
            url: this.side.default_image,
          },
        ];
      }
    },
    handleRemove() {
      this.side.sys_logo = "",
      this.fileList = []
    },
    handleRemove1() {
      this.side.start_image = "",
      this.fileList1 = []
    },
    handleRemove2() {
      this.side.default_image = "",
      this.fileList2 = []
    },
    newVersion() {
      this.layerTitle = "新增";
      this.layerShow = true;
      this.isEdit = false;
    },
    edit(row) {
      this.layerTitle = "编辑";
      this.layerShow = true;
      this.isEdit = true;
      // this.id = row.id;
      // this.name = row.name;
      this.side = row
      this.fileList = [{url:row.sys_logo}]
      this.fileList1 = [{url:row.start_image}]
      this.fileList2 = [{url:row.default_image}]

    },
    layerSave() {
      let _this = this;
      let action = "";
      if (!this.name) {
        this.setAlert({ msg: "请填写oem版本名称", type: false });
      }
      if (this.isEdit) {
        action = "edit";
      } else {
        action = "add";
      }
      apiPost({
        url: APIs.OEM_VERSION,
        data: {
          action: action,
          // id: this.id,
          // name: this.name,
          ...this.side
        },
        success(res) {
          if (res.code === "0") {
            _this.getList();
            _this.layerCancel();
            _this.setAlert({ msg: res.msg, type: true });
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(res) {
          _this.setAlert({ msg: res.msg, type: false });
        },
      });
    },
    layerCancel() {
      this.id = "";
      this.name = "";
      this.layerShow = false;
    },
    ensureCancel() {
      this.id = "";
      this.ensureShow = false;
    },
    ensureDone() {
      let _this = this;
      apiPost({
        url: APIs.OEM_VERSION,
        data: {
          action: "delete",
          id: this.id,
        },
        success(res) {
          if (res.code === "0") {
            _this.getList();
            _this.ensureCancel();
            _this.setAlert({ msg: res.msg, type: true });
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
        error(res) {
          _this.setAlert({ msg: res.msg, type: false });
        },
      });
    },
    operate(id) {
      this.ensureShow = true;
      this.id = id;
    },
    getList(index) {
      this.search.start = index || 0;
      let _this = this;
      apiPost({
        url: APIs.OEM_VERSION,
        data: {
          action: "lists",
          start: this.search.start,
          length: this.search.length,
        },
        success(res) {
          if (res.code === "0") {
            _this.dataList = res.data;
            _this.search.total = res.total;
          } else {
            _this.dataList = [];
            _this.search.total = 0;
          }
        },
        error(res) {
          _this.dataList = [];
          _this.search.total = 0;
        },
      });
    },
    ...actions,
  },
  mounted() {
    this.getList();
  },
};
</script>


<style lang="less">
@import url("../../less/common.less");

#oemSet {
  .form {
    .pdt(30px);
    .pdl(60px);
  }
  .field{
    width: 100%;
  }

  .disUoloadSty .el-upload--picture-card {
    display: none;
    /* 上传按钮隐藏 */
  }
}
</style>
